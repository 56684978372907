@import 'styles/main';

.activity {
  width: 9px;
  height: 9px;
  border-radius: 5px;

  &_active {
    &-true {
      background-color: $color-green;
    }

    &-false {
      background-color: $color-gray-light;
    }

    &-warning {
      background-color: $color-warning;
    }

    &-connecting {
      background-color: #ffa400;
    }
  }
}
