@import 'styles/main';

.locations {
  .header-container {
    h1 {
      span {
        color: $color-red;
      }
    }
  }

  .add-container,
  .edit-container,
  .form-container,
  .button-container,
  .save-container,
  .buttons-container {
    display: flex;

    .p-button {
      background-color: $color-red;
      border-color: $color-red;

      &:hover {
        color: $color-red !important;
        background: #fff !important;
        border-color: $color-red !important;
      }
    }

    .p-button-outlined {
      background-color: transparent;
      color: $color-red !important;

      &:hover {
        color: #fff !important;
        background: $color-red !important;
        border-width: 1px;
      }
    }
  }

  .table-container {

    table {

      tbody {
        tr {

          td {
            padding: 15px 8px;

            a {
              color: $color-red !important;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .id-head {
        width: 50px;
      }

      .date-head {
        width: 140px;
      }

      .id-body {
        width: 50px;
      }

      .activity-data {
        width: 5px;
      }
    }
  }


  .paginator-container {
    .p-paginator {
      .p-paginator-pages {
        .p-paginator-page {
          &.p-highlight {
            background-color: $color-red;
          }
        }
      }
    }
  }

  .timer {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
